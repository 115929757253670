<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 icon-vis2 gray-bg visitor-management-screen reception-desk-screen"
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: routeHelp
        }"
      >
        <template v-slot:left>
          <template v-if="receptionDesks && receptionDesks.length === 1">
            <home-button />
          </template>
          <template v-else>
            <router-link
              :to="{
                name: backLinkName,
                params: $route.params
              }"
            >
              <icon icon="#cx-hea1-arrow-left" />
            </router-link>
          </template>
        </template>
        <div class="component-title">
          {{ receptionDeskInfo ? receptionDeskInfo.name : "" }}
        </div>
        <template v-slot:right>
          <router-link
            :to="{
              name: routeAddVisitor,
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
    </template>

    <section class="visitors-body">
      <div class="visitors-options">
        <div>
          <span
            class="visitor-selected-date"
            @click="showCalendarDatePicker = !showCalendarDatePicker"
          >
            {{ displayDate(selectedDateFromParsed) }} -
            {{ displayDate(selectedDateToParsed) }}
          </span>
          <div class="visitor-calendar-wrapper" v-if="showCalendarDatePicker">
            <select-date-range
              :selectDateFn="selectCalendarDate"
              :show-date-picker="showCalendarDatePicker"
              :dates-to-watch="[selectedDateFrom, selectedDateTo]"
              :selected-dates="[selectedDateFrom, selectedDateTo]"
            />
          </div>
        </div>

        <section class="visitors-summary">
          <section
            v-for="(visitorStatus, index) in visitorStatuses"
            :key="index"
          >
            <Popper
              hover
              openDelay="500"
              :content="
                displayLabelName(
                  'visitor-management',
                  'reception-desks',
                  visitorStatus.label
                )
              "
            >
              <div
                class="visitors-summary-item"
                @click="activeVisitorStatus = visitorStatus.status"
              >
                <icon
                  :icon="visitorStatus.icon"
                  width="55"
                  height="28"
                  :class="
                    activeVisitorStatus === visitorStatus.status ? 'active' : ''
                  "
                />
                <div
                  :class="
                    `${
                      activeVisitorStatus === visitorStatus.status
                        ? 'active'
                        : ''
                    } visitor-count`
                  "
                >
                  {{ visitorStatus.count }}
                </div>
              </div>
            </Popper>
          </section>
        </section>
        <search
          @changedInput="setSearchQuery"
          @clear="setSearchQuery()"
          :hasFilters="false"
          :simpleSearch="true"
        />
      </div>

      <section class="visitors-section">
        <!-- Visitors start -->
        <!-- Header -->
        <ul class="clebex-item-section pill visitors-header">
          <li class="clebex-item-section-item">
            <div class="clebex-item-content-wrapper grey-bg">
              <div></div>
              <!-- name -->
              <span class="label">
                <span class="highlight">{{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "visitor"
                  )
                }}</span>
              </span>
              <!-- company -->
              <span class="label">
                <span class="highlight">{{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "company"
                  )
                }}</span>
              </span>
              <!-- host -->
              <span class="label">
                <span class="highlight">{{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "host"
                  )
                }}</span>
              </span>
              <!-- arrival -->
              <span class="label">
                <span class="highlight">{{
                  displayLabelName(
                    "visitor-management",
                    "reception-desks",
                    "arrival"
                  )
                }}</span>
              </span>
              <div>
                &nbsp;
              </div>
            </div>
          </li>
        </ul>
        <!-- Visitors by Meeting -->
        <visitors-grouped
          :group="group"
          :filtered-visitors="filteredVisitors"
          :visitor-statuses="visitorStatuses"
          :format-arrival-date="formatArrivalDate"
        />
        <!-- End Visitors by Meeting -->
      </section>
    </section>
    <slider-modal
      v-if="showStatusDialog"
      :show="showStatusDialog"
      :data="modalData"
      confirm-button-label="Close"
      :confirm-action="cancelStatusDialog"
      @close="cancelStatusDialog"
    >
      <template v-slot="{ data }">
        <visit-status-modal :data="data"></visit-status-modal>
      </template>
    </slider-modal>
    <template v-slot:footer>
      <visitor-management-footer-menu :setGroup="setGroup">
        <qr-code-scanner-button store="visitorManagement/setQrScreenActive" />
      </visitor-management-footer-menu>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";
import QrCodeScannerButton from "@/components/global/QrCodeScannerButton";
import VisitorManagementFooterMenu from "@/components/visitor-management/VisitorManagementFooterMenu";
import VisitorsGrouped from "@/components/visitor-management/ReceptionDesk/VisitorsGrouped";
import { subscribe, unsubscribe } from "@/services/ws-service";
import { wsEvents } from "@/services/constants";
import Search from "@/components/search/Search";
import visitStatuses, {
  S_CREATED,
  S_CHECKED_IN,
  S_CHECKED_OUT,
  S_ABSENT
} from "@/services/consts/modules/visitor-management/statuses";
import { S_MULTIPLE } from "../../services/consts/modules/visitor-management/statuses";
import Popper from "vue3-popper";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "ReceptionDesk",
  mixins: [helpOnlineMixin],
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange: defineAsyncComponent(() =>
      import("@/components/global/SelectDateRange")
    ),
    SliderModal: defineAsyncComponent(() =>
      import("@/components/global/SliderModal")
    ),
    QrCodeScannerButton,
    VisitorManagementFooterMenu,
    Search,
    VisitorsGrouped,
    VisitStatusModal: defineAsyncComponent(() =>
      import("@/components/visitor-management/VisitStatusModal")
    ),
    Popper
  },
  created() {
    this.fetchData();
    if (this.deskId) {
      this.channel = subscribe(
        "receptionDesk-visitor",
        [this.deskId],
        wsEvents.receptionDesk.visitor.changed,
        this.wsCallback
      );
    }
  },
  watch: {
    group: {
      handler() {
        if (this.visitors && !this.visitors[this.group]) {
          this.getVisitors();
        }
      }
    },
    deskId: {
      handler(desk) {
        if (desk) {
          this.clearReceptionDeskVisitors();
          this.fetchData();
        }

        if (this.channel) {
          unsubscribe(this.channel);
        }
        this.channel = subscribe(
          "receptionDesk-visitor",
          [desk],
          wsEvents.receptionDesk.visitor.changed,
          this.wsCallback
        );
      }
    },
    // This should update visitors badges count in top header next to search
    visitors: {
      handler(data) {
        if (!data) {
          return;
        }
        // reset all statuses count
        this.visitorStatuses.map(vs => {
          vs.count = 0;
        });
        // remove empty element
        delete data[this.group][""];
        Object.keys(data[this.group]).forEach(g => {
          Object.keys(data[this.group][g]).forEach(u => {
            Object.keys(data[this.group][g][u]).forEach(v => {
              this.visitorStatuses[0].count++;
              const visit = data[this.group][g][u][v];
              const index = this.visitorStatuses.findIndex(
                vs => vs.status === visit.visit.visit_status_id
              );
              this.visitorStatuses[index].count++;
            });
          });
        });
      },
      deep: true
    },
    refreshVisitors: {
      handler() {
        if (this.refreshVisitors) {
          this.clearReceptionDeskVisitors();
          this.getVisitors();
          this.$store.commit("visitorManagement/setRefreshVisitors", false, {
            root: true
          });
        }
      }
    }
  },
  data() {
    return {
      showCalendarDatePicker: false,
      activeVisitorStatus: 0,
      visitorStatuses: [
        { ...visitStatuses[S_MULTIPLE], count: 0 },
        { ...visitStatuses[S_CREATED], count: 0 },
        { ...visitStatuses[S_CHECKED_IN], count: 0 },
        { ...visitStatuses[S_CHECKED_OUT], count: 0 },
        { ...visitStatuses[S_ABSENT], count: 0 }
      ],
      channel: null,
      selectedDateFrom: DateTime.now().toFormat("yyyy-dd-MM"),
      selectedDateTo: DateTime.now()
        .plus({ days: 30 })
        .toFormat("yyyy-dd-MM"),
      selectedDateFromParsed: DateTime.now().toFormat("yyyy-MM-dd"),
      selectedDateToParsed: DateTime.now()
        .plus({ days: 30 })
        .toFormat("yyyy-MM-dd"),
      selectedDateEventFrom: DateTime.now().toFormat("yyyy-MM-dd"),
      selectedDateEventTo: DateTime.now()
        .plus({ days: 30 })
        .toFormat("yyyy-MM-dd"),
      group: "user.full_name",
      helpSlug: "visitor-reception-desk"
    };
  },
  methods: {
    ...mapActions("visitorManagement", [
      "getReceptionDeskInfo",
      "getReceptionDeskVisitors",
      "getReceptionDeskMyVisitors",
      "setModalData",
      "setModalQueue",
      "pushToModalQueue",
      "setSearchQuery",
      "clearReceptionDeskVisitors"
    ]),
    getVisitors() {
      if (this.$route.name.includes("my-visitors")) {
        return this.getReceptionDeskMyVisitors({
          deskId: this.deskId,
          params: {
            datetime_from: this.selectedDateEventFrom,
            datetime_to: this.selectedDateEventTo,
            group: this.group
          }
        });
      }
      return this.getReceptionDeskVisitors({
        deskId: this.deskId,
        params: {
          datetime_from: this.selectedDateEventFrom,
          datetime_to: this.selectedDateEventTo,
          group: this.group
        }
      });
    },
    setGroup(group) {
      this.group = group;
      this.setModalQueue();
    },
    selectCalendarDate(dates) {
      if (!dates[0]) {
        this.selectedDateFrom = this.selectedDateFromParsed = this.selectedDateEventFrom = null;
        return;
      }

      this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
        "yyyy-dd-MM"
      );
      this.selectedDateFromParsed = dates[0].eventDate;
      this.selectedDateEventFrom = dates[0].eventDate;

      /**
       * Wait for second date to be set and then we may make call
       */
      if (!dates[1]) {
        this.selectedDateTo = this.selectedDateToParsed = this.selectedDateEventTo = null;
        return;
      }

      this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
        "yyyy-dd-MM"
      );
      this.selectedDateToParsed = dates[1].eventDate;
      this.selectedDateEventTo = dates[1].eventDate;

      this.$router.push({ name: this.routeReceptionDesk });
      this.getVisitors().then(() => {
        this.showCalendarDatePicker = false;
      });
    },
    wsCallback() {
      this.getVisitors();
    },
    cancelStatusDialog() {
      this.$store.commit("visitorManagement/setShowStatusDialog", false, {
        root: true
      });
      this.setModalQueue();
      this.getVisitors();
    },
    fetchData() {
      if (this.deskId) {
        this.getReceptionDeskInfo(this.deskId);
        this.getVisitors();
      }
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }

      return formatDate(date, this.globalDateFormat, true);
    },
    formatArrivalDate(arrivalDate) {
      return formatDate(
        arrivalDate,
        `${this.globalDateFormat} ${this.globalTimeFormat}`
      );
    }
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("visitorManagement", [
      "receptionDeskInfo",
      "visitors",
      "receptionDesks",
      "showStatusDialog",
      "modalQueue",
      "searchQuery",
      "refreshVisitors"
    ]),
    routeReceptionDesk() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-reception-desk";
      }
      return "r_visitor-management-reception-desk";
    },
    routeAddVisitor() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-reception-desk-add-visitor";
      }
      return "r_reception-desk-add-visitor";
    },
    routeHelp() {
      if (this.$route.name.includes("my-visitors")) {
        return "r_my-visitors-visitor-reception-desk-help-online";
      }
      return "r_visitor-reception-desk-help-online";
    },
    modalData() {
      return this.modalQueue;
    },
    deskId() {
      const { receptionDeskId } = this.$route.params;
      return receptionDeskId;
    },
    filteredVisitors() {
      if (!this.visitors || !this.visitors[this.group]) {
        return [];
      }
      let visitorsCopy = this.visitors[this.group];
      if (!this.searchQuery && !this.activeVisitorStatus) {
        return visitorsCopy;
      }

      let list = {};
      Object.keys(visitorsCopy).forEach(group => {
        Object.keys(visitorsCopy[group]).forEach(user => {
          Object.keys(visitorsCopy[group][user]).forEach(visitIndex => {
            const visit = visitorsCopy[group][user][visitIndex];
            let included = true;

            if (this.searchQuery) {
              const query = this.searchQuery.toLowerCase();
              included =
                visit.full_name.toLowerCase().includes(query) ||
                (visit.company &&
                  visit.company.toLowerCase().includes(query)) ||
                (visit.host &&
                  visit.host.full_name.toLowerCase().includes(query));
            }

            if (
              this.activeVisitorStatus &&
              this.activeVisitorStatus !== visit.visit.visit_status_id
            ) {
              included = false;
            }
            // Return only included
            if (included) {
              if (!list[group]) {
                list[group] = {};
              }
              if (!list[group][user]) {
                list[group][user] = [];
              }
              list[group][user].push(visit);
            }
          });
        });
      });
      return list;
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  beforeUnmount() {
    if (this.channel) {
      unsubscribe(this.channel);
      this.channel = null;
    }
    this.$store.commit("visitorManagement/setReceptionDeskInfo", null, {
      root: true
    });
    this.$store.commit("visitorManagement/setReceptionDeskVisitors", null, {
      root: true
    });
    this.$store.commit("user/setAssignableContainers", null, { root: true });
  }
};
</script>
